import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getScaleFromValue} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.helper';
import {TIMELINE_MAX_WIDTH_PER_SECOND, TIMELINE_MIN_WIDTH_PER_SECOND} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.types';

interface State {
  isDesignItemSelected?: boolean;
  trimEnabledForAudioId?: string;
  timelineContentHeight?: number;
  bottomBarHeight?: number;
  widthPerSecond: number;
  fitWidthPerSecond: number;
  timelineScale?: number;
  isPanning: boolean;
  isDesignResizing: boolean;
  durationWhileResizing: number;
  maxDurationWhileResizing: number;
  bottomShadowState: boolean;
  minWidthAccordingToPosterDuration: number;
  isItemDragging: boolean;
  isItemResizing: boolean;
}

const initialState: State = {
  isDesignItemSelected: false,
  trimEnabledForAudioId: '',
  timelineContentHeight: 48,
  bottomBarHeight: 60,
  widthPerSecond: 120,
  fitWidthPerSecond: 20,
  timelineScale: undefined,
  isPanning: false,
  isDesignResizing: false,
  durationWhileResizing: 0,
  maxDurationWhileResizing: 0,
  bottomShadowState: false,
  minWidthAccordingToPosterDuration: TIMELINE_MIN_WIDTH_PER_SECOND,
  isItemDragging: false,
  isItemResizing: false,
};

export const posterEditorWebBottomBarSlice = createSlice({
  name: 'posterEditorWebBottomBar',
  initialState,
  reducers: {
    updateDesignItemSelectionState: (state, action: PayloadAction<boolean>): void => {
      state.isDesignItemSelected = action.payload;
    },
    updateTrimEnabledForAudioId: (state, action: PayloadAction<string>): void => {
      state.trimEnabledForAudioId = action.payload;
    },
    updateTimelineContentHeight: (state, action: PayloadAction<number>): void => {
      state.timelineContentHeight = action.payload;
    },
    updateTimelineBottomBarHeight: (state, action: PayloadAction<number>): void => {
      state.bottomBarHeight = action.payload;
    },
    updateTimelineWidthPerSecond: (state, action: PayloadAction<number>): void => {
      if (action.payload < Math.max(TIMELINE_MIN_WIDTH_PER_SECOND, state.minWidthAccordingToPosterDuration)) {
        state.timelineScale = getScaleFromValue(Math.max(TIMELINE_MIN_WIDTH_PER_SECOND, state.minWidthAccordingToPosterDuration), state.fitWidthPerSecond);
        state.widthPerSecond = Math.max(TIMELINE_MIN_WIDTH_PER_SECOND, state.minWidthAccordingToPosterDuration);
      } else if (action.payload > TIMELINE_MAX_WIDTH_PER_SECOND) {
        state.timelineScale = getScaleFromValue(TIMELINE_MAX_WIDTH_PER_SECOND, state.fitWidthPerSecond);
        state.widthPerSecond = TIMELINE_MAX_WIDTH_PER_SECOND;
      } else {
        state.timelineScale = getScaleFromValue(action.payload, state.fitWidthPerSecond);
        state.widthPerSecond = action.payload;
      }
    },
    updateTimelineFitWidthPerSecond: (state, action: PayloadAction<number>): void => {
      state.fitWidthPerSecond = action.payload;
    },
    updateTimelineScale: (state, action: PayloadAction<number>): void => {
      state.timelineScale = action.payload;
    },
    updatePanningStatus: (state, action: PayloadAction<boolean>): void => {
      state.isPanning = action.payload;
    },
    updateIsDesignItemResizing: (state, action: PayloadAction<boolean>): void => {
      state.isDesignResizing = action.payload;
    },
    updateDuplicateDuration: (state, action: PayloadAction<number>): void => {
      state.durationWhileResizing = action.payload;
      if (state.maxDurationWhileResizing < action.payload) {
        state.maxDurationWhileResizing = action.payload;
      }
    },
    updateDuplicateMaxDuration: (state, action: PayloadAction<number>): void => {
      state.maxDurationWhileResizing = action.payload;
    },
    updateBottomShadowState: (state, action: PayloadAction<boolean>): void => {
      state.bottomShadowState = action.payload;
    },
    updatePosterMinWidthAccordingToDuration: (state, action: PayloadAction<number>): void => {
      state.minWidthAccordingToPosterDuration = action.payload;
    },
    updateItemResizeState: (state, action: PayloadAction<boolean>) => {
      state.isItemResizing = action.payload;
    },
    updateItemDraggingState: (state, action: PayloadAction<boolean>) => {
      state.isItemDragging = action.payload;
    },
  },
});

export const {
  updateDesignItemSelectionState,
  updateTimelineFitWidthPerSecond,
  updateTimelineWidthPerSecond,
  updateTrimEnabledForAudioId,
  updateTimelineBottomBarHeight,
  updateTimelineContentHeight,
  updateTimelineScale,
  updatePanningStatus,
  updateIsDesignItemResizing,
  updateDuplicateDuration,
  updateDuplicateMaxDuration,
  updateBottomShadowState,
  updatePosterMinWidthAccordingToDuration,
  updateItemResizeState,
  updateItemDraggingState,
} = posterEditorWebBottomBarSlice.actions;

export const posterEditorWebBottomBarReducer = posterEditorWebBottomBarSlice.reducer;
