import {TIMELINE_MAX_WIDTH_PER_SECOND, TIMELINE_MIN_WIDTH_PER_SECOND} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.types';
import type {ItemDefinition} from '@Libraries/timeline/types';
import type {CSSProperties} from 'react';
import {updateTimelineWidthPerSecond} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.reducer';
import {GA4EventName, GA4EventParamName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';

export const getScaleFromValue = (widthPerSec: number, fitWidth: number): number => {
  return calculateScalePercentage(widthPerSec, TIMELINE_MIN_WIDTH_PER_SECOND, fitWidth, TIMELINE_MAX_WIDTH_PER_SECOND);
};

function roundToNearest5(value: number): number {
  return Math.round(value / 5) * 5;
}

function calculateScalePercentage(widthPerSecond: number, minWidth: number, fitWidth: number, maxWidth: number): number {
  const contentWidth = Math.max(minWidth, Math.min(widthPerSecond, maxWidth));

  const minPercentage = 20;
  const fitPercentage = 80;
  const maxPercentage = 400;

  const range1 = fitWidth - minWidth;
  const range2 = maxWidth - fitWidth;

  let scalePercentage: number;

  if (contentWidth <= fitWidth) {
    const scaleFactor1 = (contentWidth - minWidth) / range1;
    scalePercentage = minPercentage + scaleFactor1 * (fitPercentage - minPercentage);
  } else {
    const normalizedFactor = (contentWidth - fitWidth) / range2;
    const exponentialFactor = 2 ** normalizedFactor - 1; // This grows rapidly as normalizedFactor increases
    scalePercentage = fitPercentage + exponentialFactor * (maxPercentage - fitPercentage);
  }

  scalePercentage = roundToNearest5(scalePercentage);

  return Math.min(maxPercentage, scalePercentage);
}

export const TIMELINE_DESIGN_ITEM_ID = 'design-seek';

export enum TimelineItemTypeId {
  Design = 'design-timeline',
  Audio = 'audio-timeline',
}

export const generateTimelineItems = (): Array<ItemDefinition> => {
  return [...generateDesignSeekItem(), ...generateAudioItems()];
};

export const generateDesignSeekItem = (): Array<ItemDefinition> => {
  const items: Array<ItemDefinition> = [];
  const posterDuration = window.posterEditor?.whiteboard?.getCurrentPage().getDuration();

  if (!posterDuration) {
    return items;
  }

  const item: ItemDefinition = {
    id: TIMELINE_DESIGN_ITEM_ID,
    rowId: TimelineItemTypeId.Design,
    span: {
      start: 0,
      end: posterDuration,
    },
  };

  return [item];
};

export const generateAudioItems = (): Array<ItemDefinition> => {
  const items: Array<ItemDefinition> = [];
  const audioHashmap = window.posterEditor?.whiteboard?.audioClips.audioItemsHashMap;
  if (!audioHashmap) {
    return [];
  }
  const keys = Object.keys(audioHashmap);

  for (const eachKey of keys) {
    if (audioHashmap) {
      const item: ItemDefinition = {
        id: audioHashmap[eachKey].uid,
        rowId: TimelineItemTypeId.Audio,
        disabled: false,
        span: {
          start: audioHashmap[eachKey].onPosterStartTime,
          end: audioHashmap[eachKey].onPosterStartTime + audioHashmap[eachKey].audioPlayer.getPlaybackDuration(),
        },
      };
      items.push(item);
    }
  }

  return items;
};

export const getTimelineItemTransformProperty = (itemStyle: CSSProperties): string => {
  if (!itemStyle.transform) {
    return '';
  }

  const result = itemStyle.transform.substring(itemStyle.transform.indexOf('(') + 1, itemStyle.transform.lastIndexOf(')'));
  const splittedResult = result.split(',');
  return `translate3d(${splittedResult[0]} , 0px , ${splittedResult[2]})`;
};

export const zoomTimelineIn = (currentWidthPerSecond: number): void => {
  const scale = currentWidthPerSecond < 40 ? 1.4 : 1.1;
  const value = scale * currentWidthPerSecond;
  window.PMW.redux.store.dispatch(updateTimelineWidthPerSecond(value));
};

export const zoomTimelineOut = (currentWidthPerSecond: number): void => {
  const scale = currentWidthPerSecond < 56 ? 1.4 : 1.1;
  const value = currentWidthPerSecond / scale;

  window.PMW.redux.store.dispatch(updateTimelineWidthPerSecond(value));
};

export const fireGAEventForTimelineOpen = (): void => {
  const isMobile = isEditorMobileVariant();
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN, {
    [GA4EventParamName.TYPE]: isMobile ? 'mobile' : 'web',
  });
};

export const fireGaEventForTimelineOpenFromBottomBar = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN_BOTTOM_BAR);
};

export const fireGaEventForTimelineOpenFromAudioIcon = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN_AUDIO_ICON);
};
